import { createContext, FC, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as _ from "lodash";

import {
  Enterprise,
  EnterpriseAppState,
  FarmEntity,
  FarmInputData,
  FarmInputDataset,
  FarmInputValue,
  IOFC
} from "@ctra/api";
import { isPending, Nullable } from "@ctra/utils";

import { useFarm } from "@farms";

interface ContextType {
  values: Record<string, FarmInputData>;
  api: {
    adjust: (farmId: FarmEntity["id"], datasetName: string, value: FarmInputValue) => void;
    delete: (farmId: FarmEntity["id"], datasetName: string, value: FarmInputValue) => void;
  };
  meta: {
    dataSets?: Nullable<FarmInputDataset[]>;
    isUpdating: boolean;
    isDeleting: boolean;
    isLoading: boolean;
  };
}

const DefaultContext = createContext<ContextType>({
  values: {},
  api: {
    adjust: _.noop,
    delete: _.noop
  },
  meta: {
    dataSets: null,
    isLoading: true,
    isUpdating: false,
    isDeleting: false
  }
});

/**
 * Farm inputs
 * @param children
 * @private
 */
const ContextProvider: FC = ({ children }) => {
  const dispatch = useDispatch();
  const { farm } = useFarm();

  /**
   * Get the data sets for constructing the UI
   * @type {Nullable<FarmInputDataset[]>}
   */
  const dataSets: Nullable<FarmInputDataset[]> = useSelector<
    EnterpriseAppState,
    Nullable<FarmInputDataset[]>
  >(Enterprise.entities.getFarmInputDatasets);

  /**
   * Get the data for the farm
   * @type {Nullable<Record<string, FarmInputData>>}
   */
  const data: Nullable<Record<string, FarmInputData>> = useSelector<
    EnterpriseAppState,
    Nullable<Record<string, FarmInputData>>
  >((state) => Enterprise.entities.getFarmInputs(state, { farmId: farm?.id }));

  useEffect(() => {
    if (dataSets && farm?.id) {
      _.forEach(dataSets, ({ name }) => {
        dispatch(IOFC.actions.fetchFarmInputs.start(farm.id, name));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSets?.length, farm?.id]);

  /**
   * Fetch the data sets for the UI
   */
  useEffect(() => {
    if (!dataSets) {
      dispatch(IOFC.actions.fetchDatasets.start());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSets?.length]);

  /**
   * Tell if the page is loading
   * @type {boolean}
   */
  const isLoading: boolean = useSelector<EnterpriseAppState, boolean>((state) => {
    return _.some([
      isPending(state, IOFC.types.FETCH_DATA_SETS),
      isPending(state, IOFC.types.FETCH_FARM_INPUTS, {
        primaryValue: farm?.id
      })
    ]);
  });

  /**
   * Tell if the page is updating
   * @type {boolean}
   */
  const isUpdating: boolean = useSelector<EnterpriseAppState, boolean>((state) => {
    return _.some(
      _.map(dataSets, ({ name }) =>
        isPending(state, IOFC.types.INSERT_FARM_INPUT, {
          primaryValue: name
        })
      )
    );
  });

  /**
   * Tell if the page is deleting
   * @type {boolean}
   */
  const isDeleting: boolean = useSelector<EnterpriseAppState, boolean>((state) => {
    return _.some(
      _.map(dataSets, ({ name }) =>
        isPending(state, IOFC.types.DELETE_FARM_INPUT, {
          primaryValue: name
        })
      )
    );
  });

  return (
    <DefaultContext.Provider
      value={{
        values: _.defaultTo(data, {}),
        api: {
          adjust: (farmId, datasetName, values) =>
            dispatch(IOFC.actions.insertFarmInput.start(farmId, datasetName, values)),
          delete: (farmID, datasetName, value) =>
            dispatch(IOFC.actions.deleteFarmInput.start(farmID, datasetName, value))
        },
        meta: {
          isLoading,
          isUpdating,
          isDeleting,
          dataSets
        }
      }}
    >
      {children}
    </DefaultContext.Provider>
  );
};

export const FarmInputsContext = {
  Provider: ContextProvider,
  Consumer: DefaultContext.Consumer
};

/**
 * Farm inputs hook
 */
export const useFarmInputs = (): ContextType => useContext(DefaultContext);
