import { FC, useState, useEffect, ComponentProps } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import * as _ from "lodash";

import {
  CtraLayout,
  Typography,
  AntDesignForm as Form,
  AntDesignDatePicker as DatePicker,
  Button,
  Alert,
  message
} from "@ctra/components";

import { EnterpriseAppState, FarmSummaries } from "@ctra/api";
import { useTranslation, Enterprise as Content, parseLocoTemplate } from "@ctra/i18n";
import { Nullable, isPending, isFulfilled, isRejected } from "@ctra/utils";
import { useGoogleAnalytics } from "@ctra/analytics";

import { useFarm } from "@farms";
import { useLocalization } from "@base";

import { GAAction } from "../../analytics";
import { FarmSummarySubscriptionsContext } from "../../providers";
import { FarmBriefingSettings } from "../FarmBriefingSettings";
import styles from "./FarmSummaryPage.module.less";

const { ContentWrapper, WidgetWrapper } = CtraLayout;
const { Paragraph } = Typography;
const { RangePicker } = DatePicker;

/**
 * Farm summary page
 * @return {JSX.Element}
 * @constructor
 */
const FarmSummaryPage: FC = (): JSX.Element => {
  const { t } = useTranslation();
  const { dateFormat } = useLocalization();
  const dispatch = useDispatch();
  const { farm } = useFarm();
  const { trackEvent } = useGoogleAnalytics();
  const [timePeriod, setTimePeriod] = useState<Nullable<[string, string]>>(null);

  const {
    farmSummaries: {
      page: {
        title,
        description,
        create,
        alerts,
        create: { loading }
      }
    }
  } = Content;

  /**
   * Loading state
   * @type {boolean}
   */
  const isLoading: boolean = useSelector<EnterpriseAppState, boolean>((state) =>
    isPending(state, FarmSummaries.types.CREATE_FARM_SUMMARY)
  );

  /**
   * Created state
   * @type {boolean}
   */
  const isCreated: boolean = useSelector<EnterpriseAppState, boolean>((state) =>
    isFulfilled(state, FarmSummaries.types.CREATE_FARM_SUMMARY)
  );

  /**
   * Failed state
   */
  const [isFailed, { error, details }] = useSelector<
    EnterpriseAppState,
    [
      boolean,
      {
        error: string;
        statusCode: number;
        details: string;
      }
    ]
  >((state) =>
    isRejected(state, FarmSummaries.types.CREATE_FARM_SUMMARY, {
      withPayload: true
    })
  );

  /**
   * Content paragraphs coming from Loco
   * @type {Array<string>}
   */
  const textArray: Array<string> = _.defaultTo(
    parseLocoTemplate(t<string>(description), {
      trim: true
    }),
    []
  );

  /**
   * Create summary
   */
  const createSummary = () => {
    if (timePeriod && farm?.id) {
      const [start, end] = timePeriod;

      trackEvent(GAAction.makeFarmSummary);
      dispatch(FarmSummaries.actions.createFarmSummary.start(farm.id, start, end));
    }
  };

  useEffect(() => {
    if (timePeriod && (isCreated || isFailed)) {
      if (isCreated) {
        message.success(t<string>(alerts.success));
      } else if (isFailed) {
        message.error(_.isString(error) ? error : _.isString(details) ? details : t<string>(alerts.error));
      }

      setTimePeriod(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreated, isFailed]);

  return (
    <section className={styles.PageContent}>
      {farm?.id ? (
        <>
          <WidgetWrapper
            style={{ marginBottom: "24px" }}
            title={t<string>(title)}
            loading={isLoading}
            tip={t<string>(loading)}
          >
            <ContentWrapper title={t<string>(create.title)}>
              {_.map(textArray, (text, index) => (
                <Paragraph key={index}>{text}</Paragraph>
              ))}
              <Form layout="vertical">
                <Form.Item name="range" label={t<string>(create.form.labels.timePeriod)}>
                  <RangePicker
                    allowClear
                    format={dateFormat}
                    disabledDate={(current) => current && current.isAfter(moment().startOf("day"))}
                    value={
                      timePeriod
                        ? (_.map(timePeriod, _.unary(moment)) as ComponentProps<typeof RangePicker>["value"])
                        : null
                    }
                    onChange={(values) => {
                      if (values && values[0] && values[1]) {
                        const [start, end] = values;
                        setTimePeriod([start.startOf("day").format(), end.endOf("day").format()]);
                      } else {
                        setTimePeriod(null);
                      }
                    }}
                  />
                </Form.Item>
                <Button type="primary" onClick={createSummary}>
                  {t<string>(create.form.labels.submit)}
                </Button>
              </Form>
            </ContentWrapper>
          </WidgetWrapper>
          <FarmSummarySubscriptionsContext.Provider>
            <FarmBriefingSettings placement="main" />
          </FarmSummarySubscriptionsContext.Provider>
        </>
      ) : (
        <Alert
          type="info"
          showIcon
          message={t<string>(alerts.selectFarm.title)}
          description={t<string>(alerts.selectFarm.description)}
        />
      )}
    </section>
  );
};

export default FarmSummaryPage;
